<template>
    <div>
      <v-card flat>
        <v-card-text>
          <v-card-text style="max-width: 100%; overflow-x: auto" class="my-0">
            <v-row justify="end">
              <v-col cols="12" sm="6" class="text-right">
                <v-chip
                style="height: 40px"
                outlined
                small
                class="pr-1 text-center"
              >
                <!-- <v-btn icon color="primary" @click="addOrder()"
                  ><v-icon>add_circle_outline</v-icon></v-btn
                > -->
                <v-text-field
                  placeholder="Search"
                  class="mb-1"
                  hide-details
                  rounded
                  clearable
                  dense
                  style="width: 300px"
                  v-model="search"
                ></v-text-field>
              </v-chip>
              </v-col>
            </v-row>
            <div :style="{ width: tableWidth }" class="mt-2" v-if="displayData.length > 0">
              <table :width="'100%'" >
                <tr class="mx-0 px-0">
                  <draggable
                    v-model="selectedHeaders"
                    tag="tr"
                    :style="{ cursor: columnDrag ? 'grabbing' : 'grab' }"
                    :animation="200"
                    @change="updateUserLayout()"
                    ghost-class="ghost-card"
                    @start="columnDrag = true"
                    @end="columnDrag = false"
                  >
                    <td
                      class="text-center mx-0 px-0"
                      style="
                        cursor: grab;
                        border-right: 1px solid grey;
                        min-height: 20px;
                        min-width: 20px;
                      "
                      v-for="(header, index) in headers"
                      :key="index"
                      :width="header.width ?? '150px'"
                    >
                      <div>
                        <v-row align="center" justify="center" class="py-2">
                         
                          <b style="font-size: 12px">{{ header.text }}</b>
                          <v-btn
                            v-if="!header.disableSort"
                            class="ml-1"
                            icon
                            x-small
                            @click="changeSortBy(header.value)"
                          >
                            <v-icon
                              v-if="sortBy != header.value"
                              small
                              color="grey"
                              >arrow_upward</v-icon
                            >
                            <v-icon v-else-if="sortDesc" small
                              >arrow_downward</v-icon
                            >
                            <v-icon v-else small>arrow_upward</v-icon>
                          </v-btn>
                          <v-badge
                            v-if="!header.disableFilter"
                            :content="
                              filters[header.value]
                                ? filters[header.value].length
                                : false
                            "
                            :value="
                              filters[header.value] &&
                              filters[header.value].length > 0
                            "
                            color="primary"
                            offset-y="10"
                          >
                            <v-menu offset-y left :close-on-content-click="false">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  x-small
                                  color="grey"
                                  icon
                                  ><v-icon small>filter_alt</v-icon></v-btn
                                >
                              </template>
                              <v-card
                                style="
                                  background: var(--v-modal-base) !important;
                                  border-radius: 20px;
                                "
                              >
                                <v-card-text>
                                  <v-list
                                    dense
                                    style="
                                      background: var(--v-modal-base) !important;
                                      border-radius: 20px;
                                    "
                                  >
                                    <v-text-field
                                      hide-details
                                      rounded
                                      dense
                                      outlined
                                      clearable
                                      prepend-inner-icon="search"
                                      placeholder="Search"
                                      autofocus
                                      v-model="searchHeaders[header.value]"
                                      @input="updateSearchKey()"
                                    ></v-text-field>
                                    <v-list
                                      dense
                                      style="
                                        max-height: 50vh;
                                        overflow-y: auto;
                                        background: var(
                                          --v-modal-base
                                        ) !important;
                                      "
                                      :key="searchKey"
                                    >
                                      <v-list-item
                                        v-for="(item, index) in headerOptions(
                                          header.value
                                        )"
                                        :key="index"
                                      >
                                        <v-list-item-action class="pr-0">
                                          <v-checkbox
                                            v-model="filters[header.value]"
                                            :value="item.value"
                                            hide-details
                                            dense
                                            @change="calculateFilteredResults()"
                                          ></v-checkbox>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                          <v-list-item-title>
                                            {{ item.name }}
                                          </v-list-item-title>
                                        </v-list-item-content>
                                      </v-list-item>
                                      <v-list-item
                                        v-if="
                                          headerOptions(header.value).length == 0
                                        "
                                      >
                                        <v-list-item-content class="text-center">
                                          <span style="font-size: 12px"
                                            >No results found</span
                                          >
                                        </v-list-item-content>
                                      </v-list-item>
                                    </v-list>
                                  </v-list>
                                </v-card-text>
                              </v-card>
                            </v-menu>
                          </v-badge>
                        </v-row>
                      </div>
                    </td>
                  </draggable>
                </tr>
              </table>
              <v-divider></v-divider>
              <div
                class="mt-1 pt-1"
                :style="{ width: '100%' }"
                v-if="displayData.length > 0"
              >
                <v-virtual-scroll
                  v-if="displayData.length > 0"
                  :bench="10"
                  :items="displayData"
                  :height="calculatedHeight"
                  :width="'100%'"
                  item-height="35"
  
                >
                  <template v-slot:default="{ item }">
                    <div
                      class="text-center my-0 py-0"
                      :style="{
                        overflow: 'auto',
                        display: 'flex',
                        'flex-direction': 'row',
                        width: '100%',
                        'background-color':
                        item.id && selectedOrderItem && selectedOrderItem.id == item.id
                            ? 'rgba(12, 97, 24, 0.6)'
                            : 'transparent',
                      }"
                    >
                    <div v-if="item.displayType == 'header'" style="width: 100%">
                      <td
                        class="text-center mt-0 mb-0 pb-0"
                      >
                      {{ item.title }} <v-chip class="mx-1" small color="teal" outlined>
                        <v-icon small left>confirmation_number</v-icon>
                        Orders {{ item.orders }}
                      </v-chip>
                      <v-chip class="mx-1" color="blue-grey" small outlined>
                        <v-icon small left>widgets</v-icon>
                        Containers {{ item.containers }}
                      </v-chip>
                      </td>
                    </div>
                    <div v-else :style="{width: tableWidth}">
                      <td
                        v-for="(header, index) in headers"
                        :key="index"
                        :style="{ width: header.width ?? '150px' }"
                        class="text-center mt-0 mb-0 pb-0"
                      >
                        <div v-if="header.value == 'actions'" >
                           <v-btn icon @click="previewRequest(item)" small>
                             <v-icon small>launch</v-icon>
                           </v-btn>
                        </div>
                        <div v-else-if="header.value == 'userFullName'" >
                           <v-tooltip top v-if="item.orderUser">
                           <template v-slot:activator="{ on }">
                            <v-avatar size="24" class="ml-1" v-on="on" :color="item.orderUser.avatar ? 'white': 'secondary'" style="cursor:pointer"  @click="addToFilter(item.userFullName, 'userFullName')">
                             <v-img v-if="item.orderUser.avatar" contain :src="item.orderUser.avatar"></v-img>
                             <h4 v-else>{{ item.orderUser.firstname.charAt(0) }}</h4>
                            </v-avatar>
                           </template>
                           <span style="font-size: 12px">{{
                             item.orderUser.firstname
                           }} {{  item.orderUser.surname }}</span>
                         </v-tooltip>
                        </div>
                        <div v-else-if="header.value == 'orderStatus'" class="text-center" >
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                :color="statusColor(item.orderStatus)"
                                small
                                icon
                                v-on="on"
                                @click="addToFilter(item.orderStatus, 'orderStatus')"
                              >
                                <v-icon>{{ statusIcon(item.orderStatus) }}</v-icon>
                              </v-btn>
                            </template>
                            <span style="font-size: 12px">{{
                              $Format.capitalizeFirstLetter(item.orderStatus)
                            }}</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-else-if="header.value == 'type'" 
                          class="text-center"
                        >
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                :color="bookingTypeColor(item.type)"
                                small
                                icon
                                v-on="on"
                                @click="addToFilter(item.type, 'type')"
                              >
                                <v-icon>{{ bookingTypeIcon(item.type) }}</v-icon>
                              </v-btn>
                            </template>
                            <span style="font-size: 12px">{{
                              $Format.capitalizeFirstLetter(item.type)
                            }}</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-else-if="header.value == 'vesselVoyage'" 
                          class="text-left"
                        >
                          <v-chip
                            v-if="item.vesselVoyage"
                            small
                            class="primaryText--text"
                            outlined
                            @click="
                              addToFilter(item.vesselVoyage, 'vesselVoyage')
                            "
                            style="border: none; font-size: 11px"
                            ><span>{{ item.vesselVoyage }}</span>
                          </v-chip>
                        </div>
                        <div
                          v-else-if="header.value == 'portOfLoadValue'" 
                          class="text-center"
                        >
                          <v-tooltip top v-if="item.portOfLoadValue">
                            <template v-slot:activator="{ on }">
                              <v-chip
                                outlined
                                style="border: none; font-size: 11px"
                                v-on="on"
                                small
                                @click="
                                  addToFilter(
                                    item.portOfLoadValue,
                                    'portOfLoadValue'
                                  )
                                "
                              >
                                <v-avatar size="20" left>
                                  <v-img
                                    contain
                                    :src="`https://cdn.loglive.io/flags/4x3/${item.portOfLoadValue
                                      .substring(0, 2)
                                      .toLowerCase()}.svg`"
                                  ></v-img>
                                </v-avatar>
                                {{ item.portOfLoadValue }}
                              </v-chip>
                            </template>
                            <span style="font-size: 12px">{{
                              item.portOfLoadCity
                            }}</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-else-if="header.value == 'finalDestinationValue'" 
                          class="text-center"
                        >
                          <v-tooltip top v-if="item.finalDestinationValue">
                            <template v-slot:activator="{ on }">
                              <v-chip
                                outlined
                                style="border: none; font-size: 11px"
                                v-on="on"
                                small
                                @click="
                                  addToFilter(
                                    item.finalDestinationValue,
                                    'finalDestinationValue'
                                  )
                                "
                              >
                                <v-avatar size="20" left>
                                  <v-img
                                    contain
                                    :src="`https://cdn.loglive.io/flags/4x3/${item.finalDestinationValue
                                      .substring(0, 2)
                                      .toLowerCase()}.svg`"
                                  ></v-img>
                                </v-avatar>
                                {{ item.finalDestinationValue }}
                              </v-chip>
                            </template>
                            <span style="font-size: 12px">{{
                              item.finalDestinationCity
                            }}</span>
                          </v-tooltip>
                        </div>
                     
                        <div
                          v-else-if="header.value == 'consigneeName'" 
                          class="text-center"
                        >
                          <v-tooltip top v-if="item.consignee">
                            <template v-slot:activator="{ on }">
                              <v-chip
                                v-if="item.consignee"
                                small
                                class="primaryText--text"
                                outlined
                                @click="
                                  addToFilter(item.consigneeName, 'consigneeName')
                                "
                                v-on="on"
                                style="border: none"
                              >
                                <v-avatar
                                  size="24"
                                  :color="
                                    item.consignee && item.consignee.logo
                                      ? 'white'
                                      : 'secondary'
                                  "
                                  left
                                >
                                  <v-img
                                    style=""
                                    v-if="item.consignee.logo"
                                    :src="item.consignee.logo"
                                    contain
                                  ></v-img>
                                  <h3 v-else style="color: white">
                                    {{ item.consigneeName.charAt(0) }}
                                  </h3>
                                </v-avatar>
                                {{ item.consigneeName }}
                              </v-chip>
                            </template>
                            <span>{{ item.consignee.name }}</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-else-if="header.value == 'transporterName'"
                          :class="item.transporter ? 'text-left' : 'text-center'"
                        >
                          <v-tooltip top v-if="item.transporter">
                            <template v-slot:activator="{ on }">
                              <v-chip
                                v-if="item.transporter"
                                small
                                class="primaryText--text"
                                v-on="on"
                                outlined
                                @click="
                                  addToFilter(
                                    item.transporterName,
                                    'transporterName'
                                  )
                                "
                                style="border: none"
                              >
                                <v-avatar
                                  size="20"
                                  :color="
                                    item.transporter && item.transporter.logo
                                      ? 'white'
                                      : 'secondary'
                                  "
                                  left
                                >
                                  <v-img
                                    v-if="item.transporter.logo"
                                    :src="item.transporter.logo"
                                    contain
                                  ></v-img>
                                  <h3 v-else style="color: white">
                                    {{ item.transporterName.charAt(0) }}
                                  </h3>
                                </v-avatar>
                                {{ item.transporterName }}
                              </v-chip>
                            </template>
                            <span>{{ item.transporter.name }}</span>
                          </v-tooltip>
                          <v-btn
                            color="grey"
                            small
                            icon
                            v-else
                            @click="assignContainers([item])"
                            ><v-icon small>add_circle_outline</v-icon></v-btn
                          >
                        </div>
  
                        <div
                          v-else-if="header.value == 'customerName'" 
                          class="text-center"
                        >
                          <v-tooltip top v-if="item.customer">
                            <template v-slot:activator="{ on }">
                              <v-chip
                                v-if="item.customer"
                                small
                                class="primaryText--text"
                                v-on="on"
                                outlined
                                @click="
                                  addToFilter(item.customerName, 'customerName')
                                "
                                style="border: none"
                              >
                                <v-avatar
                                  size="20"
                                  :color="
                                    item.customer && item.customer.logo
                                      ? 'white'
                                      : 'secondary'
                                  "
                                  left
                                >
                                  <v-img
                                    v-if="item.customer.logo"
                                    :src="item.customer.logo"
                                    contain
                                  ></v-img>
                                  <h3 v-else style="color: white">
                                    {{ item.customerName.charAt(0) }}
                                  </h3>
                                </v-avatar>
                                {{ item.customerName }}
                              </v-chip>
                            </template>
                            <span>{{ item.customer.name }}</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-else-if="header.value == 'shipperName'"
                          class="text-center"
                        >
                          <v-tooltip top v-if="item.shipper">
                            <template v-slot:activator="{ on }">
                              <v-chip
                                v-if="item.shipper"
                                small
                                class="primaryText--text"
                                v-on="on"
                                outlined
                                @click="
                                  addToFilter(item.shipperName, 'shipperName')
                                "
                                style="border: none"
                              >
                                <v-avatar
                                  size="20"
                                  :color="
                                    item.shipper && item.shipper.logo
                                      ? 'white'
                                      : 'secondary'
                                  "
                                  left
                                >
                                  <v-img
                                    v-if="item.shipper.logo"
                                    :src="item.shipper.logo"
                                    contain
                                  ></v-img>
                                  <h3 v-else style="color: white">
                                    {{ item.shipperName.charAt(0) }}
                                  </h3>
                                </v-avatar>
                                {{ item.shipperName }}
                              </v-chip>
                            </template>
                            <span>{{ item.shipper.name }}</span>
                          </v-tooltip>
                        </div>
                        <div
                          v-else-if="header.value == 'shippingLineName'" class="text-left" 
                        >
                          <v-chip
                            v-if="item.shippingLine"
                            small
                            class="primaryText--text"
                            outlined
                            @click="
                              addToFilter(
                                item.shippingLineName,
                                'shippingLineName'
                              )
                            "
                            style="border: none"
                          >
                            <v-avatar
                              size="24"
                              :color="
                                item.shippingLine && item.shippingLine.logo
                                  ? 'white'
                                  : 'secondary'
                              "
                              left
                            >
                              <v-img
                                v-if="item.shippingLine.logo"
                                :src="item.shippingLine.logo"
                                contain
                              ></v-img>
                              <h3 v-else style="color: white">
                                {{ item.shippingLineName.charAt(0) }}
                              </h3>
                            </v-avatar>
                            {{ item.shippingLineName }}
                          </v-chip>
                        </div>
                        <div v-else-if="header.value == 'transportStatus'">
                          <v-menu offset-y v-if="item[header.value]">
                            <template v-slot:activator="{ on }">
                              <v-chip
                                small
                                class="primaryText--text"
                                :color="getStatusColor(item.transportStatus)"
                                v-on="on"
                                style="border: none"
                              >
                                {{ item[header.value] }}
                                <v-icon small right>expand_more</v-icon>
                              </v-chip>
                            </template>
                            <v-list style="overflow: hidden" dense>
                              <v-list-item
                                v-for="(status, index) in transportStatuses"
                                :key="index"
                                style="height: 35px"
                                @click="setStatus(item, status)"
                              >
                                <v-list-item-action>
                                  <v-icon :color="status.color"
                                    >fiber_manual_record</v-icon
                                  >
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ status.text }}
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </div>
                        <div v-else-if="header.value == 'etd'" >
                          <v-chip
                            v-if="item[header.value]"
                            small
                            class="primaryText--text"
                            outlined
                            @click="addToFilter(item[header.value], header.value)"
                            style="border: none"
                          >
                            {{ formatDate(item[header.value]) }}
                          </v-chip>
                        </div>
                        <div v-else-if="header.value == 'vesselLegs'" >
                          <v-btn
                            v-if="item.sailingSchedule"
                            icon
                            small
                            outlined
                            @click="viewBookingLegs(item)"
                            :color="
                              item.sailingSchedule.legs == 1 ? 'success' : 'blue'
                            "
                            >{{ item.sailingSchedule.legs }}</v-btn
                          >
                        </div>
                        <div v-else-if="header.value == 'numContainers'" >
                          <v-chip
                            v-if="item[header.value]"
                            small
                            @click="addToFilter(item[header.value], header.value)"
                          >
                            {{ item[header.value] }}
                          </v-chip>
                        </div>
                        <div v-else-if="header.value == 'eta'" >
                          <v-chip
                            v-if="item[header.value]"
                            small
                            class="primaryText--text"
                            outlined
                            @click="addToFilter(item[header.value], header.value)"
                            style="border: none"
                          >
                            {{ formatDate(item[header.value]) }}
                          </v-chip>
                        </div>
  
                        <div v-else-if="header.value == 'movementType'" >
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-chip
                                v-on="on"
                                small
                                class="primaryText--text"
                                outlined
                                @click="
                                  addToFilter(item.movementType, header.value)
                                "
                                style="border: none"
                              >
                                <v-avatar
                                  :color="
                                    item.movementType == 'EXPORT'
                                      ? 'deep-orange'
                                      : 'blue'
                                  "
                                  left
                                >
                                  <h4 style="color: white">
                                    {{ item.movementType.charAt(0) }}
                                  </h4>
                                </v-avatar>
                                {{ item.orderNo }}
                              </v-chip>
                            </template>
                            <span
                              >{{
                                $Format.capitalizeFirstLetter(item.movementType)
                              }}
                              Booking</span
                            >
                          </v-tooltip>
                        </div>
                        <div v-else >
                          <v-chip
                            v-if="item[header.value]"
                            small
                            class="primaryText--text"
                            outlined
                            @click="addToFilter(item[header.value], header.value)"
                            style="border: none"
                          >
                            {{ item[header.value] }}
                          </v-chip>
                        </div>
                      </td>
                    </div>
                    </div>
                  
  
                    <v-divider class="my-0 py-0 mt-1"></v-divider>
                  </template>
                </v-virtual-scroll>
              </div>
            </div>
            <div class="container" v-if="!loading && displayData.length == 0">
              <h3 style="color: grey">No Results Found</h3>
            </div>
            <div class="container" v-else-if="loading && displayData.length == 0">
              <valhalla-loading height="500px" />
            </div>
          </v-card-text>
        </v-card-text>
      </v-card>
      
      <v-dialog v-model="previewModal" width="1500px" :fullscreen="$vuetify.breakpoint.mobile">
    <PreviewBookingRequest :bookingRequest="bookingRequestItem" :containers="containers" :containerTypes="containerTypes" @close="previewModal=false" @refresh="getOrders"/>
    </v-dialog>
    </div>
  </template>
  <script>
  import dateFormat from 'dateformat'
  import draggable from "vuedraggable";
  import * as moment from 'moment'
  import PreviewBookingRequest from './Dialogs/PreviewBookingRequest.vue';
  export default {
    components: {
      draggable,
      PreviewBookingRequest
    },
    data: () => ({
        bookingRequestItem: {},
      columnDrag: false,
      containers: [],
      containerTypes: [],
      filteredResults: [],
      filters: null,
      filteredValues: [],
      headers: [
        {
          text: "Action",
          align: "center",
          disableSort: true,
          disableFilter: true,
          value: "actions",
          width: "100px",
        },
        {
          text: "User",
          align: "center",
          disableSort: false,
          disableFilter: false,
          value: "userFullName",
          width: "100px",
        },
        
        {
          text: "Status",
          value: "orderStatus",
          align: "center",
          width: "100px",
          filterable: true,
        },
        {
          text: "Order No",
          value: "movementType",
          align: "center",
          filterable: true,
        },
        {
          text: "Vessel",
          value: "vesselVoyage",
          align: "center",
          width: "200px",
        },
        {
          text: "Customer",
          value: "customerName",
          width: "220px",
          filterable: true,
        },
        {
          text: "Shipper",
          value: "shipperName",
          width: "220px",
          filterable: true,
        },
        {
          text: "Consignee",
          value: "consigneeName",
          width: "220px",
          filterable: true,
        },
        // {
        //   text: "Forwarder",
        //   value: "forwarderName",
        //   width: "220px",
        //   filterable: true,
        // },
        {
          text: "Carrier",
          value: "shippingLineName",
          align: "left",
          width: '120px'
        },
        {
          text: "Week",
          value: "sailingWeek",
          align: "center",
          width: "120px",
        },
        {
          text: "POL",
          value: "portOfLoadValue",
          align: "center",
          width: "100px",
        },
        {
          text: "ETD",
          value: "etd",
          align: "center",
          width: "100px",
        },
        // {
        //     text: 'POD',
        //     value: 'portOfDischargeValue',
        //     align: 'center',
        //     filterable: true
        // },
        {
          text: "Dest.",
          value: "finalDestinationValue",
          align: "center",
          width: "100px",
        },
        {
          text: "ETA",
          value: "eta",
          align: "center",
          width: "100px",
        },
        
        {
          text: "Containers",
          value: "numContainers",
          align: "center",
          width: "125px",
        },
        {
          text: "Carrier Ref. No",
          value: "carrierReferenceNumber",
          align: "center",
          filterable: false,
        },
        {
          text: "Shipper Ref. No",
          value: "shipperReferenceNo",
          align: "center",
          width: "200px",
          filterable: false,
        },
        {
          text: "Consignee Ref. No",
          value: "consigneeReferenceNo",
          width: "200px",
          align: "center",
          filterable: false,
        },
      ],
      loading: false,
      loadingOrganisations: false,
      order: {},
      orderModal: false,
      orders: {
          data: [],
          total: 0
      },
      organisations: {
        total: 0,
        data: [],
      },
      orderQuery: undefined,
      orderStatus: [
        // { name: "Cancelled", icon: "cancel", color: "red" },
        { name: "Planned", icon: "schedule", color: "#F57C00" },
        { name: "Open", icon: "pending", color: "#2196F3" },
        { name: "Complete", icon: "check_circle_outline", color: "#4CAF50" },
        { name: "Cancelled", icon: "cancel", color: "#F44336" }
      ],
      orderTimeout: undefined,
      previewModal: false,
      search: null,
      searchHeaders: {},
      searchKey: 100,
      selectedOrderItem: {},
      selectedHeaders: [],
      showMenu: false,
      sortBy: null,
      sortDesc: false,
      updateFilterValue: 350,
      x: null,
      y: null
    }),
    computed: {
      calculatedHeight() {
        let height = window.innerHeight;
        return height * 0.63 + "px";
      },
      displayData() {
        let result = this.filteredResults;
        if (this.search) {
          result = result.filter(
            (x) =>
              (x.orderNo &&
                x.orderNo.toLowerCase().includes(this.search.toLowerCase())) ||
              (x.shipperName &&
                x.shipperName
                  .toLowerCase()
                  .includes(this.search.toLowerCase())) ||
              (x.consigneeName &&
                x.consigneeName
                  .toLowerCase()
                  .includes(this.search.toLowerCase())) ||
              (x.shippingLineName &&
                x.shippingLineName
                  .toLowerCase()
                  .includes(this.search.toLowerCase())) ||
              (x.vesselVoyage &&
                x.vesselVoyage
                  .toLowerCase()
                  .includes(this.search.toLowerCase())) ||
              (x.regimeCode &&
                x.regimeCode.toLowerCase().includes(this.search.toLowerCase())) ||
              (x.portOfLoadValue &&
                x.portOfLoadValue
                  .toLowerCase()
                  .includes(this.search.toLowerCase())) ||
              (x.portOfLoadCity &&
                x.portOfLoadCity
                  .toLowerCase()
                  .includes(this.search.toLowerCase())) ||
              (x.finalDestinationValue &&
                x.finalDestinationValue
                  .toLowerCase()
                  .includes(this.search.toLowerCase())) ||
              (x.finalDestinationCity &&
                x.finalDestinationCity
                  .toLowerCase()
                  .includes(this.search.toLowerCase())) ||
              (x.carrierReferenceNumber &&
                x.carrierReferenceNumber
                  .toLowerCase()
                  .includes(this.search.toLowerCase())) ||
              (x.shipperReferenceNo &&
                x.shipperReferenceNo
                  .toLowerCase()
                  .includes(this.search.toLowerCase())) ||
              (x.consigneeReferenceNo &&
                x.consigneeReferenceNo
                  .toLowerCase()
                  .includes(this.search.toLowerCase())) ||
                  (x.customerName &&
                x.customerName
                  .toLowerCase()
                  .includes(this.search.toLowerCase()))
          );
        }
        // result.forEach(x=>{
        //   x.weekYear = x.sailingWeek ? x.sailingWeek + ' - ' + x.sailingWeekYear: 'No Week Specified'
        // })
        let uniqueWeeks = [...new Set(result.map((x)=>x.sailingWeek))]
        uniqueWeeks.sort()
        // console.log(uniqueWeeks)
        let final = []
        for(let i=0;i<uniqueWeeks.length;i++){
          // let week = uniqueWeeks[i].split('-')[0].trim()
          // let year = uniqueWeeks[i].split('-')[1].trim()
          // let findFirstIndex = result.findIndex(x=>x.sailingWeek == week)
          // console.log(findFirstIndex, week, year, uniqueWeeks[i])
          let filtered = result.filter(x=>x.sailingWeek == uniqueWeeks[i])
          if (this.sortBy) {
            filtered = filtered.sort((a, b) => {
            if (this.sortDesc) {
              return a[this.sortBy] < b[this.sortBy] ? 1 : -1;
            } else {
              return a[this.sortBy] > b[this.sortBy] ? 1 : -1;
            }
          });
        }
          final = [...final,{
            displayType: 'header',
            title: uniqueWeeks[i],
            orders: filtered.length,
            containers: filtered.map(x=>x.numContainers).reduce((a,b)=>a+b,0)
          }, ...filtered ]
          // result.splice(findFirstIndex-1, 0, {
          //   displayType: 'header',
          //   title: 'Week: ' + uniqueWeeks[i],
          //   orders: filtered.length,
          //   containers: filtered.map(x=>x.numContainers).reduce((a,b)=>a+b,0)
          // });
        }
      
        return final;
      },
      tableWidth() {
        let result = 0;
        this.headers.forEach((x) => {
          result += parseInt(x.width ? x.width.replace("px", "") : 150);
        });
        return result + "px";
      },
    },
    async created(){
      if (!this.filters) {
        this.filters = {};
        this.resetFilters();
      }
      this.containerTypes = await this.$API.listContainerTypes();
    },
    async mounted() {
      this.load();
    },
    methods: {
      addOrder(){
        this.order = {
          recordType: 'ORDER',
          orderStatus: 'OPEN',
          status: "Draft",
          transportType: "Container",
          movementType: "EXPORT",
          type: "LOGISTICS",
          modeOfTransport: "OCEAN",
          serviceType: "FCL",
          bookingContainerItems: [],
          plannedYear: new Date().getFullYear(),
          plannedWeek: moment().isoWeek(),
        }
        this.orderModal = true
      },
      addToFilter(value, field) {
        if (this.filters[field].includes(value)) {
          this.filters[field] = this.filters[field].filter((x) => x != value);
        } else {
          this.filters[field].push(value);
        }
        this.updateFilterValue++;
        localStorage.setItem(
          "orderFilters_" + this.$store.state.currentOrg.id,
          JSON.stringify(this.filters)
        );
        let keys = Object.keys(this.filters);
        let result = [];
        keys.forEach((x) => {
          result = [
            ...result,
            ...this.filters[x].map((y) => ({
              field: x,
              fieldName: this.headers.find((z) => z.value == x).text,
              value: y,
            })),
          ];
        });
        this.filteredValues = result;
        this.calculateFilteredResults();
      },
      calculateFilteredResults() {
        let filerKeys = Object.keys(this.filters);
        let filterResult = [];
        filerKeys.forEach((x) => {
          filterResult = [
            ...filterResult,
            ...this.filters[x].map((y) => ({
              field: x,
              fieldName: this.headers.find((z) => z.value == x).text,
              value: y,
            })),
          ];
        });
        this.filteredValues = filterResult;
        let result = [...this.orders.data];
        let keys = Object.keys(this.filters);
        for (let i = 0; i < keys.length; i++) {
          let key = keys[i];
          if (this.filters[key] && this.filters[key].length > 0) {
            result = result.filter((x) => this.filters[key].includes(x[key]));
          }
        }
        this.filteredResults = result;
      },
      changeSortBy(value) {
        if (this.sortBy == value) {
          if (this.sortDesc) {
            this.sortBy = null;
            this.sortDesc = false;
          } else {
            this.sortDesc = true;
          }
        } else {
          this.sortBy = value;
          this.sortDesc = false;
        }
      },
      async load() {
        this.getOrders();
        await this.getRelations();
      },
      formatDate(date) {
        let result = null;
        if (date) {
          result = dateFormat(new Date(date), "dd-mm-yyyy");
        }
        return result;
      },
      formatDateTime(date) {
        let result = null;
        if (date) {
          result = dateFormat(date, "yyyy-mm-dd HH:MM");
        }
        return result;
      },
      async getOrders() {
        if (this.orderTimeout) {
          clearTimeout(this.orderTimeout);
        }
        if (this.loading && this.orderQuery) {
          this.orderQuery.abort();
        }
        this.loading = true;
        let params = {};
      //   params.teams = this.selectedTeams;
      //   if (this.params.dates.length == 1) {
      //     params.dates = [
      //       moment(this.params.dates[0])
      //         .startOf("isoWeek")
      //         .toISOString()
      //         .substring(0, 10),
      //       moment(this.params.dates[0])
      //         .endOf("isoWeek")
      //         .toISOString()
      //         .substring(0, 10),
      //     ];
      //   }
        params.recordType = 'ORDER'
        // params.orderStatus = 'OPEN'
        this.orderTimeout = setTimeout(async () => {
          this.orderQuery = new AbortController();
          const signal = this.orderQuery.signal;
          this.orders = await this.$API.getBookingRequests({
            params: params,
            signal,
          });
          this.calculateFilteredResults();
          this.loading = false;
        }, 500);
      },
      async getRelations() {
        this.loadingOrganisations = true;
        this.organisations = await this.$API.getRelationBasic({
          // params: this.params,
        });
        this.loadingOrganisations = false;
      },
      async previewRequest(item){
        this.bookingRequestItem = await this.$API.getOrderDetail(item.systemReference)
        this.containers = await this.$API.getOrderContainersBasic(item.id);
        this.previewModal = true
      },
      statusColor(status) {
      if(status){
        let color = this.orderStatus.find(
          (x) => x.name.toLowerCase() === status.toLowerCase()
        );
        return color ? color.color : "grey";
      } else  return 'grey'
      },
      statusIcon(status) {
      if(status){
        let find = this.orderStatus.find(
          (x) => x.name.toLowerCase() === status.toLowerCase()
        );
        return find ? find.icon : "";
      } else return 'help'
      },
      headerOptions(value) {
        let result = [
          ...new Set(
            this.orders.data
              // .filter(x => x[value])
              .map((x) => ({ name: x[value] ?? "(Blank)", value: x[value] }))
          ),
        ];
        let uniqueValues = [...new Set(result.map((x) => x.value))];
        if (this.searchHeaders[value]) {
          uniqueValues = uniqueValues.filter(
            (x) =>
              x &&
              x.toLowerCase().includes(this.searchHeaders[value].toLowerCase())
          );
        }
        result = uniqueValues.map((x) => result.find((y) => y.value === x));
        // if (value == 'status') {
        //     result.push({ name: 'Unallocated', value: null })
        // }
        result.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
        return result;
      },
      resetFilters() {
        this.filters = {};
        let keys = this.headers.map((x) => x.value);
        keys.forEach((x) => {
          this.searchHeaders[x] = null;
          this.filters[x] = [];
        });
        this.filteredValues = [];
        this.updateFilterValue++;
        localStorage.removeItem(
          "orderFilters_" + this.$store.state.currentOrg.id
        );
        this.calculateFilteredResults();
      },
      viewOrder(item) {
        this.$router.push({
          path: "/order/" + item.systemReference,
        });
      },
      viewItem(e, item) {
        e.preventDefault();
        this.showMenu = false;
        this.x = e.clientX;
        this.y = e.clientY;
        this.selectedOrderItem = item;
        this.$nextTick(() => {
          this.showMenu = true;
        });
      },
     
    }
  };
  </script>
  
  <style scoped>
  .container {
    width: 100%;
    height: 70vh;
    margin: auto;
    /* outline: solid 1px black; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  </style>